import { IList, IListItem } from '@/src/interfaces/generated/contentful';
import { Box } from '@/src/packages/components/ui/Box';
import { Button } from '@/src/packages/components/ui/Button';
import {
  Dialog,
  DialogContent,
  DialogOverlay,
} from '@/src/packages/components/ui/Dialog';
import { Heading } from '@/src/packages/components/ui/Heading';
import { Markdown } from '@/src/packages/components/ui/Markdown';
import { Section } from '@/src/packages/components/ui/Section';
import { Stack } from '@/src/packages/components/ui/Stack';
import { useDisclosure } from '@bitmap/utils/hooks/use-disclosure';
import { ArrowUpRight } from '@carbon/icons-react';
import { DialogPortal } from '@radix-ui/react-dialog';
import Image from 'next/image';
import slugify from 'slugify';
import type SwiperCore from 'swiper';
import { SwiperSlide } from 'swiper/react';
import { Swiper } from 'swiper/react';
import { useRef, useState } from 'react';
import { Keyboard, Mousewheel } from 'swiper/modules';
import { CloseButton } from '@/src/packages/components/ui/CloseButton';
import removeMd from 'remove-markdown';
import { FadeInView } from '@/src/packages/components/ui/Animations';

const JournalEntryItemModal = ({
  item,
  open,
  onClose,
}: {
  open: boolean;
  onClose(): void;
  item: IListItem;
}) => {
  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogPortal>
        <DialogOverlay>
          <DialogContent className="flex max-h-screen flex-col overflow-scroll lg:max-h-[calc(100vh-32px)]">
            <CloseButton onClick={onClose} className="absolute left-2 top-2" />

            <Box className="grid grid-cols-12">
              <Box className="order-2 col-span-12 space-y-3 p-4 md:order-1 md:col-span-5 lg:p-12">
                <Stack className="space-y-2">
                  <Heading as="h3" className="font-serif text-4xl font-medium">
                    {item.fields.name}
                  </Heading>

                  <Markdown content={item.fields.description || ''} />
                </Stack>

                <Button asChild isBlock>
                  <Box as="a" href={item.fields.website} target="_blank">
                    Visit website
                  </Box>
                </Button>
              </Box>
              <Box className="order-1 col-span-12 md:order-2 md:col-span-7">
                <ItemImage item={item} />
              </Box>
            </Box>
          </DialogContent>
        </DialogOverlay>
      </DialogPortal>
    </Dialog>
  );
};

const ItemImage = ({ item }: { item: IListItem }) => {
  return (
    <Box>
      {item.fields.images &&
        item.fields.images.map((image, i) => {
          return (
            <Box className="col-span-12 overflow-hidden" key={i}>
              <Box className="relative aspect-[4/3] bg-white lg:aspect-[1/1]">
                <Image
                  src={image.fields.file.url}
                  alt=""
                  fill
                  className="object-cover"
                />
              </Box>
            </Box>
          );
        })}
    </Box>
  );
};

const Item = ({ item }: { item: IListItem }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  const content = removeMd(item.fields.description || '').replace(
    /(\r\n|\n|\r)/gm,
    ' ',
  );

  return (
    <>
      <JournalEntryItemModal open={isOpen} onClose={onClose} item={item} />

      <Box
        className="lg:hover:bg-background-secondary flex cursor-pointer flex-col space-y-6 md:p-8"
        onClick={onOpen}
      >
        <Stack className="space-y-4">
          <Stack className="space-y-2 md:text-center">
            <Heading
              as="h3"
              className="font-serif text-3xl font-medium leading-tight"
            >
              {item.fields.name}
            </Heading>

            <Markdown content={content} className="line-clamp-3" />
          </Stack>
          <Box className="flex gap-2">
            <Button isBlock>Read more</Button>
            <Button asChild onClick={(e) => e.stopPropagation()}>
              <Box as="a" href={item.fields.website} target="_blank">
                <ArrowUpRight />
              </Box>
            </Button>
          </Box>
        </Stack>
        <ItemImage item={item} />
      </Box>
    </>
  );
};

const JournalEntryGiftGuideList = ({ list }: { list: IList }) => {
  const swiperRef = useRef<SwiperCore>();

  const [isEnd, setEnd] = useState(false);

  const [isBeginning, setBeginning] = useState(true);

  const [active, setActive] = useState(0);

  const [showArrows, setShowArrows] = useState(false);

  const updateShowArrows = (swiper: SwiperCore) => {
    const { slidesPerView } = swiper.params;

    const hasOverflowingSlides =
      typeof slidesPerView === 'number' &&
      list.fields.items.length > slidesPerView;

    setShowArrows(hasOverflowingSlides);
  };

  return (
    <Section
      className="border-t py-6 first:border-t-0 first:pt-0"
      id={slugify(list.fields.name, { lower: true })}
    >
      <Box as="header" className="flex items-center py-6">
        <Heading
          as="h2"
          className="flex-1 font-serif text-4xl leading-tight lg:text-center"
        >
          {list.fields.name}
        </Heading>

        {/* {showArrows && (
          <Box className="lg:hidden">
            <Arrows
              isEnd={isEnd}
              isBeginning={isBeginning}
              handlePrev={() => swiperRef.current?.slideTo(active - 1)}
              handleNext={() => swiperRef.current?.slideTo(active + 1)}
            />
          </Box>
        )} */}
      </Box>

      <FadeInView>
        <Box className="relative hidden grid-cols-12 md:grid">
          <Box className="absolute left-1/3 top-0 hidden h-full border-r xl:block" />
          {list.fields.items.length > 1 && (
            <Box className="absolute left-2/3 top-0 hidden h-full border-r xl:block" />
          )}
          <Box className="absolute left-1/2 top-0 hidden h-full border-r md:block xl:hidden" />

          {list.fields.items.map((item: IListItem, i: number) => {
            return (
              <Box className="col-span-12 sm:col-span-6 xl:col-span-4" key={i}>
                <Item item={item} />
              </Box>
            );
          })}
        </Box>
      </FadeInView>

      <Box className="-mx-gutter md:hidden">
        <Swiper
          modules={[Mousewheel, Keyboard]}
          onInit={(swiper) => {
            swiperRef.current = swiper;
            updateShowArrows(swiper);
          }}
          onSlideChange={(swiper) => {
            setEnd(swiper.isEnd);
            setBeginning(swiper.isBeginning);
            setActive(swiper.activeIndex);
          }}
          onResize={(swiper) => {
            setActive(swiper.activeIndex);
            updateShowArrows(swiper);
          }}
          spaceBetween={24}
          slidesPerView={1.1}
          keyboard={{
            enabled: true,
          }}
          className="!px-gutter lg:!px-0"
        >
          {list.fields.items.map((item: IListItem, i: number) => {
            return (
              <SwiperSlide key={i}>
                <Item item={item} />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </Box>
    </Section>
  );
};

export { JournalEntryGiftGuideList, JournalEntryItemModal };
