import { Box, IBoxProps } from '../Box';

import './Typography.scss';
import clsx from 'clsx';

type ITypographyProps = IBoxProps;

export const Typography = (props: ITypographyProps) => {
  const { children, className, ...rest } = props;

  return (
    <Box className={clsx(className, 'typography')} {...rest}>
      {children}
    </Box>
  );
};
