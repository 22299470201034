import { IArticleAsset } from '@/src/interfaces/generated/contentful';
import { Box } from '@/src/packages/components/ui/Box';
import { Video } from '@/src/packages/components/ui/Video';
import Image from 'next/image';
import { tv } from 'tailwind-variants';

const richTextImageStyles = tv({
  base: 'my-8',
  variants: {
    size: {
      small: '',
      large:
        '-mx-full lg:px-gutter relative inset-x-1/2 w-screen -translate-x-1/2',
    },
  },
});

const RichTextImage = ({ asset }: { asset: IArticleAsset }) => {
  const fileType = asset.fields.asset.fields.file.contentType.startsWith(
    'video',
  )
    ? 'video'
    : 'image';

  return (
    <Box
      className={richTextImageStyles({
        size: asset.fields.size === 'Large' ? 'large' : 'small',
      })}
    >
      {fileType === 'video' ? (
        <Video url={asset.fields.asset.fields.file.url} controls={true} />
      ) : (
        <Image
          className="w-full max-w-none"
          src={asset.fields.asset.fields.file.url}
          alt={asset.fields.altText}
          width={asset?.fields?.asset?.fields?.file?.details?.image?.width}
          height={asset?.fields?.asset?.fields?.file?.details?.image?.width}
          sizes="100vw"
        />
      )}
    </Box>
  );
};

export { RichTextImage };
