'use client';
import {
  Options,
  documentToReactComponents,
} from '@contentful/rich-text-react-renderer';
import { RichTextConfigBase } from './RichText.config';
import { BLOCKS, Document } from '@contentful/rich-text-types';

export type IRichTextProps = {
  data: Document | undefined;
  options?: Options | undefined;
};

export const RichText = (props: IRichTextProps) => {
  const {
    data = { nodeType: BLOCKS.DOCUMENT, content: [], data: [] },
    options = RichTextConfigBase,
  } = props;

  return <>{documentToReactComponents(data, options)}</>;
};
