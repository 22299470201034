import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { Typography } from '../Typography';
import { Box, IBoxProps } from '../Box';
import { Link } from '@/src/packages/components/ui/Link';

type IMarkdownProps = {
  content: string;
} & IBoxProps;

export const Markdown = (props: IMarkdownProps) => {
  const { content, ...rest } = props;

  return (
    <Typography {...rest}>
      <ReactMarkdown
        components={{
          a: (props) => {
            return (
              <Link variant="solid" asChild>
                <Box as="a" target="_blank" href={props.href}>
                  {props.children}
                </Box>
              </Link>
            );
          },
          strong: (props) => {
            return (
              <Box as="strong" className="font-medium">
                {props.children}
              </Box>
            );
          },
        }}
      >
        {content}
      </ReactMarkdown>
    </Typography>
  );
};
