export default function supabaseLoader({
  src,
  width,
  quality,
}: {
  src: string;
  width: number;
  quality: number;
}) {
  let url = `${src}?w=${width}&q=${quality || 75}&fm=webp`;

  if (!src.startsWith('https:')) url = `https:${url}`;

  return url;
}
