'use client';
import { JournalEntryGiftGuideList } from '@/src/packages/components/pages/JournalEntry/JournalEntryGiftGuide/JournalEntryGiftGuideList';
import { Box } from '@/src/packages/components/ui/Box';
import { Heading } from '@/src/packages/components/ui/Heading';
import { Link } from '@/src/packages/components/ui/Link';
import { RichTextImage } from '@/src/packages/components/ui/RichText/RichTextImage';
import { Options } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { Block, Document, Inline } from '@contentful/rich-text-types';
import Image from 'next/image';
import { tv } from 'tailwind-variants';

export type IRichTextProps = { data: Document | undefined };

export const RichTextConfigBase: Options = {
  renderNode: {
    [INLINES.HYPERLINK]: (node: Block | Inline, children) => {
      return (
        <Link variant="solid" asChild>
          <Box
            as="a"
            href={node.data.uri}
            rel="noopener noreferrer"
            target="_blank"
            className="text-text-primary"
          >
            {children}
          </Box>
        </Link>
      );
    },
  },
};

const removeParagraphWrapper = (nodes: any) => {
  if (nodes.length && nodes[0].props.children) {
    return nodes[0].props.children;
  }
  return nodes;
};

const baseRichTextStyles = tv({
  base: 'text-xl mb-6 last:mb-0 leading-[1.6] text-text-secondary',
});

const baseHeadingRichTextStyles = tv({
  base: 'mb-2 mt-8 font-medium',
});

export const RichTextConfigArticle: Options = {
  renderNode: {
    ...RichTextConfigBase.renderNode,
    [BLOCKS.EMBEDDED_ENTRY]: (node: Block | Inline) => {
      if (node.data.target.sys.contentType.sys.id === 'list') {
        return <JournalEntryGiftGuideList list={node.data.target} />;
      }

      if (node.data.target.sys.contentType.sys.id === 'articleAsset') {
        return <RichTextImage asset={node.data.target} />;
      }
    },
    [BLOCKS.EMBEDDED_ASSET]: (node: Block | Inline) => {
      return (
        <Image
          className="mb-6 w-full max-w-none"
          src={node.data.target.fields.file.url}
          alt=""
          width={node.data.target.fields.file.details.image.width}
          height={node.data.target.fields.file.details.image.height}
        />
      );
    },
    [BLOCKS.LIST_ITEM]: (node, children) => {
      return (
        <Box
          as="li"
          className={baseRichTextStyles({
            className: 'mb-4 last-of-type:mb-0',
          })}
        >
          {children}
        </Box>
      );
    },
    [BLOCKS.UL_LIST]: (node, children) => {
      return (
        <Box
          as="ul"
          className={baseRichTextStyles({
            className: 'list-disc pl-4 marker:text-xs',
          })}
        >
          {children}
        </Box>
      );
    },
    [BLOCKS.HEADING_2]: (node, children) => {
      return (
        <Heading
          as="h2"
          className={baseHeadingRichTextStyles({ className: 'text-3xl' })}
        >
          {children}
        </Heading>
      );
    },
    [BLOCKS.HEADING_3]: (node, children) => {
      return (
        <Heading
          as="h3"
          className={baseHeadingRichTextStyles({ className: 'text-2xl' })}
        >
          {children}
        </Heading>
      );
    },
    [BLOCKS.QUOTE]: (node, children) => {
      return (
        <Box
          as="blockquote"
          className="text-text-primary my-12 px-6 font-serif text-2xl lg:px-12 lg:text-3xl"
        >
          “{removeParagraphWrapper(children)}”
        </Box>
      );
    },
    [BLOCKS.PARAGRAPH]: (node, children) => {
      return (
        <Box as="p" className={baseRichTextStyles()}>
          {children}
        </Box>
      );
    },
  },
};
