import { Box, IBoxProps } from '@/src/packages/components/ui/Box';
import { Center } from '@/src/packages/components/ui/Center';
import { cn } from '@/src/packages/utils/cn';
import { Close } from '@carbon/icons-react';
import { ForwardedRef, forwardRef } from 'react';

export const CloseButton = forwardRef(
  (props: IBoxProps, ref: ForwardedRef<HTMLButtonElement>) => {
    const { className, ...rest } = props;

    return (
      <Box
        as="button"
        ref={ref}
        className={cn(
          'text-text-secondary hover:border-border-primary-active bg-background-primary hover:bg-background-primary-active hover:text-text-primary h-8 w-8 cursor-pointer border transition-all',
          className,
        )}
        {...rest}
      >
        <Center>
          <Close size={20} />
        </Center>
      </Box>
    );
  },
);

CloseButton.displayName = 'CloseButton';
