import cx from "classnames";

interface ICenterProps {
  children: React.ReactNode;
  className?: string;
}

export const Center = ({ children, className }: ICenterProps) => {
  return (
    <div
      className={cx(
        "flex items-center justify-center h-full w-full",
        className
      )}
    >
      {children}
    </div>
  );
};
