import { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player/lazy';
import { Box } from '@/src/packages/components/ui/Box';
import { cn } from '@/src/packages/utils/cn';

const Video = ({
  url,
  autoplay = false,
  controls = false,
  loop,
  className,
}: {
  url: string;
  autoplay?: boolean;
  controls?: boolean;
  loop?: boolean;
  className?: string;
}) => {
  const videoRef = useRef<ReactPlayer>(null);

  const containerRef = useRef<HTMLDivElement>(null);

  const [isPlaying, setPlaying] = useState(false);

  useEffect(() => {
    if (autoplay) setPlaying(true);
  }, [autoplay]);

  return (
    <Box
      className={cn(
        'bg-background-secondary relative aspect-square md:aspect-video',
        className,
      )}
      ref={containerRef}
    >
      <ReactPlayer
        ref={videoRef}
        url={url}
        className="object-cover"
        width="100%"
        height="100%"
        playing={isPlaying}
        onPlay={() => setPlaying(true)}
        onPause={() => setPlaying(false)}
        loop={loop}
        controls={controls}
        // Only muted videos can be autoplayed
        {...(autoplay && { muted: true, playsinline: true })}
      />
    </Box>
  );
};

const VideoManufacturing = () => {
  return (
    <Video
      url="https://videos.ctfassets.net/l194802zn0cr/71XV5uBNzWQ8d9SkNRSPQf/362bd8ae36e53a0b5f5e174e6b1eb6ec/header_promo_v1_compressed.mp4"
      autoplay
      loop
    />
  );
};

export { Video, VideoManufacturing };
